export const y1 = '#733309';
export const y2 = '#87540d';
export const y3 = '#B88115';
export const y4 = '#D4A31C';
export const y5 = '#E6BB20';
export const y6 = '#F2D047';
export const y7 = '#F5E35D';
export const y8 = '#FCF088';
export const y9 = '#FFF7C4';
export const y10 = '#FFFBEA';
export const y11 = '#FFFEFA';
export const r1 = '#610316';
export const r2 = '#8A041A';
export const r3 = '#AB091E';
export const r4 = '#CF1124';
export const r5 = '#E12D39';
export const r6 = '#EF4E4E';
export const r7 = '#F86A6A';
export const r8 = '#FF9B9B';
export const r9 = '#FFBDBD';
export const r10 = '#FFE3E3';
export const r11 = '#FFF1F1';
export const b1 = '#034388';
export const b2 = '#0B5FA3';
export const b3 = '#1271BF';
export const b4 = '#1980D4';
export const b5 = '#2B8FED';
export const b6 = '#40A5F7';
export const b7 = '#66B5FA';
export const b8 = '#8BCAFD';
export const b9 = '#B3D9FF';
export const b10 = '#E3F0FF';
export const b11 = '#F5FCFF';
export const n1 = '#102A43';
export const n2 = '#243B53';
export const n3 = '#334E68';
export const n4 = '#486581';
export const n5 = '#627D98';
export const n6 = '#829AB1';
export const n7 = '#9FB3C8';
export const n8 = '#BCCCDC';
export const n9 = '#D9E2EC';
export const n10 = '#E8ECF0';
export const n11 = '#F7FAFC';
export const g1 = '#014D40';
export const g2 = '#0C6B58';
export const g3 = '#147D64';
export const g4 = '#199473';
export const g5 = '#27AB83';
export const g6 = '#3EBD93';
export const g7 = '#65D6AD';
export const g8 = '#8EEDC7';
export const g9 = '#C6F7E2';
export const g10 = '#EFFCF6';
export const g11 = '#FAFFFD';
export const p1 = '#44056E';
export const p2 = '#580A94';
export const p3 = '#690CB0';
export const p4 = '#7A0ECC';
export const p5 = '#8719E0';
export const p6 = '#9446ED';
export const p7 = '#A368FC';
export const p8 = '#B990FF';
export const p9 = '#DAC4FF';
export const p10 = '#F2EBFE';
export const p11 = '#F9F6FE';

export const border = n10;
export const hover = '#fafafa';
export const selected = b9;
